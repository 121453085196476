import {Heading, List, ListIcon, ListItem, Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import {ChatIcon, CheckCircleIcon, EmailIcon, StarIcon, WarningIcon, CalendarIcon, TimeIcon} from "@chakra-ui/icons";
import {useContext} from "react";
import {UserContext} from "../context/UserContext";
import moment from 'moment';

export default function Profile() {
    const [isLoggedIn, user_obj, token, , ] = useContext(UserContext);

    if (isLoggedIn()) {
        return (
            <Tabs mt="40px" p="20px" colorScheme="blue" variant="enclosed">
                <TabList>
                    <Tab _selected={{ color: "white", bg: "blue.400"}}>Account Info</Tab>
                    <Tab _selected={{ color: "white", bg: "blue.400"}}>Purchase History</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <List spacing={4}>
                            <ListItem>
                                <ListIcon as={EmailIcon} />
                                {user_obj.email}
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CalendarIcon} />
                                {moment(user_obj.created_at).fromNow()}
                            </ListItem>
                            <ListItem>
                                <ListIcon as={TimeIcon} />
                                {moment(user_obj.last_seen).fromNow()}
                            </ListItem>
                            <ListItem>
                                <ListIcon as={ChatIcon} />
                                {token}
                            </ListItem>
                            <ListItem>
                                <ListIcon as={StarIcon} />
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </ListItem>
                        </List>

                    </TabPanel>
                    <TabPanel>
                        <List spacing={4}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.400" />
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.400" />
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={WarningIcon} color="yellow.400" />
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.400" />
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={WarningIcon} color="yellow.400" />
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            </ListItem>
                        </List>

                    </TabPanel>
                </TabPanels>
            </Tabs>
        )
    } else {
        return (
            <Heading>Not logged in!</Heading>
        )
    }

}