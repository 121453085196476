import {Box, Container, Heading, Text} from "@chakra-ui/react";
import {useLoaderData} from "react-router-dom";
// import {useContext} from "react";
// import {UserContext} from "../context/UserContext";


const text_tags = ["topic1", "blest", "regn"]

export default function Knowledge() {
    const full_content = useLoaderData()
    return (
        <Container>
            <Container mb="30px">
                <Heading>Knowledge database</Heading>
                <Text>Text content on this page is loaded all at once on page load from the API</Text>
            </Container>
            {text_tags.map(function(tag, i){
                return (
                    <Box mb="10px" key={tag}>
                        <Heading>{tag}</Heading>
                        <Text>{full_content[tag]}</Text>
                    </Box>
                )
            })}
        </Container>
    )
}


export const knowledgeLoader = async () => {
    var full_content = {}
    for (let i = 0; i < text_tags.length; i++) {
        let tag = text_tags[i];
        try {
            const res = await fetch(
                process.env.REACT_APP_API_URL + "/site-content/text-by-tag/" + tag, {
                    mode: "cors",
                    headers: {
                        'Accept': 'application/json',
                    },
                }
            )
            const data = await res.json()
            if ("content" in data) {
                full_content[tag] = data.content;
            } else {
                console.log(data)
                console.log("Content reply didn't have any content?");
            }
        } catch(error) {
            console.log(error)
        }
    }
    return full_content
}