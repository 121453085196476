import LocalizedStrings from 'react-localization';

let ui_strings = new LocalizedStrings({
    en: {
        "frontpage": "Frontpage",
        "products": "Products",
        "knowledge": "Knowledge",
        "about_us": "About us",
        "profile": "Profile",
        "create_user": "Create user",
    },
    no: {
        "frontpage": "Forside",
        "products": "Produkter",
        "knowledge": "Kunnskap",
        "about_us": "Om oss",
        "profile": "Profil",
        "create_user": "Opprett bruker",
    }
});

// ui_strings.setLanguage("no")

export { ui_strings };