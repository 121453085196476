import {
    Avatar,
    Box, Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Divider,
    Flex,
    Heading,
    HStack,
    Text
} from "@chakra-ui/react";
import moment from "moment";
import {EditIcon, ViewIcon} from "@chakra-ui/icons";
import React from "react";
import PropTypes from "prop-types";
import TextContent from "./TextContent";

export default function UserCard({user}) {
    let card_color = "orange.300"
    switch(parseInt(user.user_level)) {
        case 0:
            card_color = "blue.300";
            break;
        case 1:
            card_color = "yellow.300";
            break;
        case 2:
            card_color = "orange.400";
            break;
        default:
            card_color = "gray.500";
    }
    return (
        <Card borderTop="8px" borderColor={card_color}>
            <CardHeader>
                <Flex gap={5}>
                    <Avatar
                        src={process.env.REACT_APP_API_URL + "/site-content/image/icons/user.webp"}
                    />
                    <Box>
                        <Heading as="h3" size="sm">ID: <b>{user.id}</b></Heading>
                        <Text fontSize="sm">{user.email}</Text>
                    </Box>
                </Flex>
            </CardHeader>
            <CardBody color="gray.500">
                <Text>Created: {moment(user.created_at).fromNow()}</Text>
                <Text>Last seen: {moment(user.last_seen).fromNow()}</Text>
            </CardBody>

            <Divider borderColor="gray.200"></Divider>

            <CardFooter>
                <HStack>
                    <Button variant="ghost" leftIcon={<ViewIcon />}>Watch</Button>
                    <Button variant="ghost" leftIcon={<EditIcon />}>Edit</Button>
                </HStack>
            </CardFooter>
        </Card>
    )
}

UserCard.propTypes = {
    user: PropTypes.object.isRequired
}