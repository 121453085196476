import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import { ChakraProvider} from "@chakra-ui/react";
import {UserProvider} from "./context/UserContext";


ReactDOM.createRoot(document.getElementById('root')).render(
    <UserProvider>

        <React.StrictMode>
            <ChakraProvider>
                <App />
            </ChakraProvider>
        </React.StrictMode>

    </UserProvider>
)