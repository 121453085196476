import {useLoaderData} from "react-router-dom";
import React from "react";
import {
    Box, Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader, Center, Container,
    Divider,
    Flex,
    Heading,
    HStack,
    SimpleGrid,
    Text
} from "@chakra-ui/react";
import {EditIcon, ViewIcon} from "@chakra-ui/icons";
import { ui_strings } from "../Localization.js"


export default function Create() {
    const products = useLoaderData()
    return (
        <Box>
            <Container p="20px">
                <Center>
                    <Heading as="h2">{ui_strings.products}</Heading>
                </Center>
            </Container>
            <SimpleGrid spacing={10} minChildWidth={"300px"}>
                {products && products.map(product => (
                    <Card key={product.id} borderTop="8px" borderColor="pink.300">
                        <CardHeader>
                            <Flex gap={5}>
                                <Box w="75px" h="75px">
                                    <Text>AV</Text>
                                </Box>
                                <Box>
                                    <Heading as="h3" size="sm">{product.name}</Heading>
                                    <Text size="sm">Product ID: <b>{product.id}</b></Text>
                                </Box>
                            </Flex>
                            <Text>Price: <b>{product.base_price}</b></Text>
                        </CardHeader>
                        <CardBody color="gray.500">
                        </CardBody>

                        <Divider borderColor="gray.200"></Divider>

                        <CardFooter>
                            <HStack>
                                <Button variant="ghost" leftIcon={<EditIcon />}>Edit</Button>
                            </HStack>
                        </CardFooter>
                    </Card>
                ))}
            </SimpleGrid>
        </Box>
    )
}


export const productLoader = async () => {
    try {
        const res = await fetch(
            process.env.REACT_APP_API_URL + "/products/", {
                mode: "cors",
                headers: {
                    'Accept': 'application/json',
                },
            }
        )
        return res.json()
    } catch(error) {
        console.log(error);
        return []
    }
}