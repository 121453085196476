import {
    Heading,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure, ModalContent, FormControl, Input, FormHelperText, Button, FormLabel, useToast
} from "@chakra-ui/react";
import {React, useContext, useEffect} from "react";
import {UserContext} from "../context/UserContext";


export default function LoginModal() {
    const { isOpen, onClose, onOpen} = useDisclosure();
    const [isLoggedIn, user_obj, token, setUser, setToken] = useContext(UserContext);
    const toast = useToast();

    async function loginUser(event){
        // const data = await request.formData()

        const data = {
            username: event.target.email.value,
            password: event.target.password.value
        }

        var formBody = [];
        for (var property in data) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(data[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        try {
            const res = await fetch(
                process.env.REACT_APP_API_URL + "/login", {
                    mode: "cors",
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: formBody
                }
            );

            if (res.status == 200) {
                const resp_data = await res.json();
                if ("access-token" in resp_data) {
                    toast({
                        title: "Success",
                        description: "You has been logged in!",
                        duration: 5000,
                        isClosable: true,
                        status: 'success'
                    })
                    setToken(resp_data["access-token"])
                    onClose()
                } else {
                    toast({
                        title: "Failure",
                        description: "Unable to login: status " + res.status.toString(),
                        duration: 3000,
                        isClosable: true,
                        status: 'error'
                    })
                    console.log(resp_data);
                }
            } else if (res.status == 401) {
                toast({
                    title: "Failure",
                    description: "Login attempt failed.",
                    duration: 3000,
                    isClosable: true,
                    status: 'error'
                })
            }
        } catch(error) {
            console.log(error);
            toast({
                title: "Unhandled error",
                description: "There was an unhandled network error when trying to log in",
                duration: 5000,
                isClosable: true,
                status: 'error'
            })
        }
        // setToken(resp_data.access_token);
    }

    const handleSubmit = e => {
        e.preventDefault()
        loginUser(e)
    }

    // useEffect(() => {
    //     async function loginUser({ request}){
    //         const data = await request.formData()
    //         const res = await fetch(
    //             process.env.REACT_APP_API_URL + "/login", {
    //                 mode: "cors",
    //                 headers: {
    //                     'Accept': 'application/json',
    //                     'Content-Type': 'application/x-www-form-urlencoded'
    //                 },
    //                 body: data
    //             }
    //         );
    //         console.log(await res.json());
    //         // setToken(resp_data.access_token);
    //     };
    //
    //     if (!token) {
    //         loginUser()
    //     }
    // }, []);
    return (
        <>
            <Button onClick={onOpen}>Log in</Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>

                    <ModalHeader>
                        <Heading>Log in</Heading>
                        <ModalCloseButton />
                    </ModalHeader>

                    <ModalBody>
                        <form
                        id="login-form"
                        onSubmit={handleSubmit}>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input type="email" name="email" />
                                <FormHelperText>
                                    Fer fricks sake
                                </FormHelperText>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Password</FormLabel>
                                <Input type="password" name="password" />
                                <FormHelperText>
                                    Fer fricks sake twice
                                </FormHelperText>
                            </FormControl>
                        </form>
                    </ModalBody>

                    <ModalFooter>
                        <Button type="submit" form="login-form" colorScheme="blue">
                            Log in
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </>
    )
}
//
// export const loginUser = async({ request}) => {
//     const data = await request.formData()
//     const res = await fetch(
//         process.env.REACT_APP_API_URL + "/login", {
//             mode: "cors",
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/x-www-form-urlencoded'
//             },
//             body: data
//         }
//     )
//     return res.json()
// }