import React, {createContext, useContext, useEffect, useState} from "react";
import {Heading, Text} from "@chakra-ui/react";

export const UserContext = createContext();

export const UserProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem("access-token"));
    const [user, setUser] = useState(localStorage.getItem("itblocks-user"));
    var isLoggedIn = function() {
        return (token != null && token != "null" && user != null)
    }

    useEffect(() => {
        const fetchUser = async () => {
            console.log("FETCHING USER");
            const requestOptions = {
                method: "GET",
                headers: {
                    mode: "cors",
                    'Accept': 'application/json',
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };

            var user_data = null
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/users/me/",
                    requestOptions
                )
                console.log("Contacted API");
                if (!response.ok) {
                    setToken(null);
                    setUser(null);
                } else {
                    user_data = await response.json();
                    setUser(user_data);
                }

            } catch (error) {
                console.log("Error when trying to get user");
                setToken(null);
                setUser(null);
            }

            localStorage.setItem("itblocks-user", user_data);
            localStorage.setItem("access-token", token);
        }

        fetchUser();
    }, [token]);

    return (
        <UserContext.Provider value={[isLoggedIn, user, token, setUser, setToken]}>
            {props.children}
        </UserContext.Provider>
    )
};
