import {Button, Text, Flex, Heading, Spacer, HStack, useToast, Avatar, AvatarBadge} from "@chakra-ui/react";
// import { useToastHook } from "./Toasts";
import React, {useContext} from "react";
import LoginModal from "./LoginModal";
import {UserContext} from "../context/UserContext";


export default function Navbar() {
    const toast = useToast()
    // const [state, newToast] = useToastHook();
    const [isLoggedIn, user_obj, , setUser, setToken] = useContext(UserContext);
    const WarningToast = function(title, desc) {
        toast({
            title: title,
            description: desc,
            duration: 5000,
            isClosable: true,
            status: 'warning'
        })

    }

    var user = {
        "email": null
    }

    if(!isLoggedIn()) {
        // console.log("Logged in flag is false.");
    } else {
        // console.log("Logged in flag is true.");
        user = user_obj;
    }
    // console.log(user_obj);


    const logoutUser = function() {
        showToast();
        setUser(null);
        setToken(null);
    }

    const showToast = () => {
        WarningToast('Logged out', 'Only logged out locally, server has not been notified.');
    }


    let btn;
    let avatar;
    let usertext;
    if (!isLoggedIn()) {
        btn = <LoginModal />
        avatar = null
        usertext = (<Text color="gray.500" fontSize="sm">Not logged in.</Text>)
    } else {
        avatar = (
            <Avatar
                name="testepost@lol.no"
                bg="orange200"
                src={process.env.REACT_APP_API_URL + "/site-content/image/icons/user.webp"}
            >
                <AvatarBadge width="1.3em" bg="green.500">
                    <Text fontSize="xs" color="white">1</Text>
                </AvatarBadge>
            </Avatar>
        )
        btn = <Button colorScheme="blue" onClick={logoutUser}>Logout</Button>
        usertext = (<Text>{user.email}</Text>)
    }

    return (
        <Flex as="nav" p="10px" mb="40px" alignItems="center">
            <Heading as="h1" color="blue.300">ITBlocks ISP</Heading>
            <Spacer />

            <HStack spacing="20px">
                {avatar}
                {usertext}
                {btn}
            </HStack>
        </Flex>
        // <Flex bg="gray.200" justify="space-between" wrap="wrap" gap="2">
        //     <Box w="150px" h="50px" bg="red" textAlign="center">1</Box>
        //     <Box w="150px" h="50px" flexGrow="1" bg="blue">2</Box>
        //     <Box w="150px" h="50px" flexGrow="1" bg="green">3</Box>
        //     <Box w="150px" h="50px" bg="yellow">4</Box>
        // </Flex>
    )
}