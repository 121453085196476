

    //     const res = await fetch(
    //         process.env.REACT_APP_API_URL + "/site-content/text/" + id.toString(), {
    //             mode: "cors",
    //             headers: {
    //                 'Accept': 'application/json',
    //             },
    //         }
    //     )
    //     const data = await res.json()
    //     if ("content" in data) {
    //         full_content[id] = data.content;
    //     } else {
    //     }
    // }


export function getTextContent(id=-1, tag="") {
    let base_url = process.env.REACT_APP_API_URL
    if (tag !== "") {
        base_url += "/site-content/text-by-tag/" + tag
    } else if (id >= 0) {
        base_url += "/site-content/text/" + id
    } else {
        return {}
    }

    return new Promise((resolve) => {
        const res = fetch(
            base_url
        ).then((resp) => {
            const data = resp.json();
            resolve(data)
        })
        .catch((error) => {
          console.log(error)
        });
    })
}