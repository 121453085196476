import {List, ListIcon, ListItem} from "@chakra-ui/react";
import {NavLink} from "react-router-dom";
import {CalendarIcon, EditIcon, AtSignIcon, ViewIcon, QuestionIcon, StarIcon, EmailIcon} from "@chakra-ui/icons";
import { ui_strings } from "../Localization.js"


const linkStyle = {
    color: "white",
    // textAlign: "center",
    // filter: "blur(2px)",
    transition: "0.3s",
    ':hover': {
        color: 'blue.800',
        // bg: 'blue.300',
        // filter: "none",
    },
    '.active': {
            color: 'black',
    }
}



export default function Sidebar() {
    return (
        <List color="white" fontSize="1.2em" spacing={4}>
            <ListItem sx={linkStyle}>
                <NavLink to="/">
                    <ListIcon as={ViewIcon} />
                    {ui_strings.frontpage}
                </NavLink>
            </ListItem>
            <ListItem sx={linkStyle}>
                <NavLink to="/products">
                    <ListIcon as={StarIcon}/>
                    {ui_strings.products}
                </NavLink>
            </ListItem>
            <ListItem sx={linkStyle}>
                <NavLink to="/knowledge">
                    <ListIcon as={QuestionIcon}/>
                    {ui_strings.knowledge}
                </NavLink>
            </ListItem>
            <ListItem sx={linkStyle}>
                <NavLink to="/about">
                    <ListIcon as={EmailIcon}/>
                    {ui_strings.about_us}
                </NavLink>
            </ListItem>
            <ListItem sx={linkStyle}>
                <NavLink to="/create">
                    <ListIcon as={EditIcon}/>
                    {ui_strings.create_user}
                </NavLink>
            </ListItem>
            <ListItem sx={linkStyle}>
                <NavLink to="/profile">
                    <ListIcon as={AtSignIcon}/>
                    {ui_strings.profile}
                </NavLink>
            </ListItem>
            <ListItem sx={linkStyle}>
                <NavLink to="/dashboard">
                    <ListIcon as={CalendarIcon}/>
                    Dashboard
                </NavLink>
            </ListItem>
        </List>
    )
}