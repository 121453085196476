import {Box, Card, CardBody, CardHeader, Container, Heading, Text} from "@chakra-ui/react";
import {useLoaderData} from "react-router-dom";
import {useState} from "react";
import TextContent from "../components/TextContent";
// import {useContext} from "react";
// import {UserContext} from "../context/UserContext";

const text_ids = [0, 3, 5, 2]


export default function Front() {
    const full_content = useLoaderData()
    return (
        // <Container>
        //     <Heading>FRONTPAGE</Heading>
        //     {text_ids.map(function(id, i){
        //         return (
        //             <Box mb="10px" key={id}>
        //                 <Text>{full_content[id]}</Text>
        //             </Box>
        //         )
        //     })}
        // </Container>
        <Container>
            <Text>Text content on this page is loaded asynchronously from the API</Text>
            {["gutta", "jenta", "opp", "ned"].map(function(tag, id){
                return (
                    <Card mb="10px" key={tag + id.toString()}>
                        <CardHeader><Heading>ID:{id} - {tag}</Heading></CardHeader>
                        <CardBody>
                            <TextContent tag={tag} key={tag}/>
                        </CardBody>
                    </Card>
                )
            })}

        </Container>
    )
}


export const frontLoader = async () => {



    var full_content = {}
    // for (let i = 0; i < text_ids.length; i++) {
    //     let id = text_ids[i];
    //     console.log("Fetching text " + id.toString())
    //     const res = await fetch(
    //         process.env.REACT_APP_API_URL + "/site-content/text/" + id.toString(), {
    //             mode: "cors",
    //             headers: {
    //                 'Accept': 'application/json',
    //             },
    //         }
    //     )
    //     const data = await res.json()
    //     if ("content" in data) {
    //         full_content[id] = data.content;
    //     } else {
    //         console.log(data)
    //         console.log("Content reply didn't have any content?");
    //     }
    // }
    return full_content
}