import {
    Box,
    Button,
    Center,
    Container,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    Radio,
    RadioGroup,
    Text,
    Stack, useToast, FormErrorMessage
} from "@chakra-ui/react";
import { useForm } from 'react-hook-form';
import {Form, redirect, useActionData} from "react-router-dom";
import React, {useState} from "react";
import {useToastHook} from "../components/Toasts";
import { ui_strings } from "../Localization.js"

export default function Create() {
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm();
    const toast = useToast();
    const [value, setValue] = React.useState('0');


    const onSubmit = async(values) => {

        const user = {
            // fiken_id: 0,
            // user_level: parseInt(data.get('user_level'), 0),
            email: values.email,
            password: values.password,
            // user_level: data.get('user_level'),
        }
        const userdata = JSON.stringify(user);

        try {
            const resp = await fetch(
                process.env.REACT_APP_API_URL + "/users/", {
                    method: "POST",
                    // mode: "no-cors",
                    headers: {
                        'Accept': 'application/json',
                        "Content-Type": "application/json",
                    },
                    body: userdata
                });
            const content = await resp.json();
            console.log(content);
            console.log(resp);

            if (resp.status === 200) {
                toast({
                    title: "User has been created",
                    description: "Blablablablablablablablablablablablabla",
                    duration: 5000,
                    isClosable: true,
                    status: 'success'
                })
            } else {
                toast({
                    title: "Status " + resp.status.toString(),
                    description: content.detail,
                    duration: 5000,
                    isClosable: true,
                    status: 'error'
                })
            }
        } catch(error) {
            toast({
                title: "Unhandled error",
                description: "There was an unhandled network error when trying create the user",
                duration: 5000,
                isClosable: true,
                status: 'error'
            })
        }
    }




    return (
        <Container>
            <Center>
                <Heading mb="40px">{ui_strings.create_user}</Heading>
            </Center>
            <Box maxW="480px">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isRequired mb="40px" isInvalid={errors.email}>
                        <FormLabel>Email:</FormLabel>
                        <Input
                            type="email" name="email" id="email" {...register('email', {
                                required: "This is required",
                                minLength: { value: 5, message: "Minimum length should be 5"}
                            })}
                        />
                        <FormHelperText>Enter the users email address.</FormHelperText>
                        <FormErrorMessage>
                            {errors.email && errors.email.message}
                        </FormErrorMessage>

                    </FormControl>
                    <FormControl isRequired mb="40px" isInvalid={errors.password}>
                        <FormLabel>Password:</FormLabel>
                        <Input
                            type="password" name="password" id="password" {...register('password', {
                                required: "This is required",
                                minLength: { value: 5, message: "Minimum length should be 5"}
                            })}
                        />
                        <FormHelperText>Enter the new users password.</FormHelperText>
                        <FormErrorMessage>
                            {errors.password && errors.password.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired mb="40px">
                        {/*<RangeSlider aria-label={['min', 'max']} defaultValue={[0, 2]}>*/}
                        {/*    <RangeSliderTrack>*/}
                        {/*        <RangeSliderFilledTrack />*/}
                        {/*    </RangeSliderTrack>*/}
                        {/*    <RangeSliderThumb index={0} />*/}
                        {/*    <RangeSliderThumb index={1} />*/}
                        {/*</RangeSlider>*/}
                        <RadioGroup onChange={setValue} value={value} defaultValue="0" name="user_level">
                            <Stack direction='row'>
                                <Radio value="0">Customer</Radio>
                                <Radio value="1">Technician</Radio>
                                <Radio value="2">Admin</Radio>
                            </Stack>
                        </RadioGroup>

                    </FormControl>
                    {/*<Button type="submit" isLoading={isSubmitting} colorScheme='green' onClick={() =>*/}
                    {/*    toast({*/}
                    {/*        title: "Ny bruker postet",*/}
                    {/*        description: "Venter på svar fra server",*/}
                    {/*        duration: 1000,*/}
                    {/*        isClosable: true,*/}
                    {/*        status: 'info'*/}
                    {/*    })*/}
                    {/*}>Create</Button>*/}
                    <Button type="submit" isLoading={isSubmitting} colorScheme='green'>Create</Button>
                </Form>
            </Box>
        </Container>
    )

}

export const createUser = async ({ request}) => {
    // const [state, newToast] = useToastHook();

    const data = await request.formData()

    const user = {
        // fiken_id: 0,
        // user_level: parseInt(data.get('user_level'), 0),
        email: data.get('email'),
        password: data.get('password'),
        // user_level: data.get('user_level'),
    }
    const userdata = JSON.stringify(user);
    console.log(userdata);

    const resp = await fetch(
        "http://127.0.0.1:8000/users/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
            },
            body: userdata
        });
    const content = await resp.json();
    const status_code = resp.status
    console.log(content);
    console.log(resp);
    if (resp.status === 200) {
        return redirect("/")
    } else {
        return(
            <Box>
                <Text>Hei babe</Text>
            </Box>
        )
    }

}
