import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider
} from "react-router-dom";

// layouts and pages
import RootLayout from './layouts/RootLayout'
import Dashboard, {userLoader} from './pages/Dashboard'
import Create from './pages/Create'
import Profile from './pages/Profile'
import Products, {productLoader} from './pages/Products'
import Knowledge, {knowledgeLoader} from "./pages/Knowledge";
import About from "./pages/About";
import Front, {frontLoader} from "./pages/Front";

// router and routes
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout />}>
            <Route index element={<Front />} loader={frontLoader}/>
            <Route path="products" element={<Products />} loader={productLoader} />
            <Route path="about" element={<About />} />
            <Route path="knowledge" element={<Knowledge />} loader={knowledgeLoader}/>
            <Route path="create" element={<Create />}/>
            <Route path="dashboard" element={<Dashboard />} loader={userLoader}/>
            <Route path="profile" element={<Profile />}/>
        </Route>
    )
)

function App() {
    return (
        <RouterProvider router={router} />
    )
}

export default App