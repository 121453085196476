import {
    Avatar,
    Box, Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Center,
    Container, Divider, Flex,
    Heading, HStack,
    SimpleGrid,
    Text
} from "@chakra-ui/react";
import React from "react";
import {useLoaderData} from "react-router-dom";
import {ViewIcon, EditIcon} from "@chakra-ui/icons";
import moment from "moment/moment";
import UserCard from "../components/UserCard";

export default function Dashboard() {
    const users = useLoaderData()

    return (
        <Box>
            <Container p="20px">
                <Center>
                    <Heading as="h2">Users</Heading>
                </Center>
            </Container>
            <SimpleGrid spacing={10} minChildWidth={"300px"}>
                {users && users.map(user => (
                    <UserCard user={user} key={user.id} />
                ))}
            </SimpleGrid>
        </Box>
        // <Container as="section" maxWidth="4xl" py="20px">
        //     <Heading my="30px" p="10px" color="blue.300"><Center>ITBlocks ISP Dashboard</Center></Heading>
        //     <Text>
        //         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus beatae earum fugiat magni odit officiis quaerat quas ratione, reiciendis vel. Deserunt dignissimos ipsa molestiae molestias nemo neque nulla provident quam rem rerum, sed temporibus, velit voluptate voluptates voluptatum? Ea error exercitationem hic labore laborum, libero molestiae non ratione reiciendis sapiente.
        //     </Text>
        //
        //     <Box my="30px" p="20px" bg="orange.400" color="white">
        //         <Text fontWeight="bold">This is a box</Text>
        //     </Box>
        //
        //     <Box sx={boxStyles}>
        //         <Text>Hello gutta!</Text>
        //     </Box>
        // </Container>
    )
}

export const userLoader = async () => {
    try {
        const res = await fetch(
            process.env.REACT_APP_API_URL + "/users/", {
                mode: "cors",
                headers: {
                    'Accept': 'application/json',
                },
            }
        )

        return res.json()
    } catch(error) {
        console.log(error)
        return []
    }
}