import React, {useEffect, useState} from "react";
import {Text} from "@chakra-ui/react";
import {getTextContent} from "../services/GetContent";
import PropTypes from "prop-types";


export default function TextContent({id = -1, tag = ""}) {
    const [fullContent, setFullContent] = useState({});

    useEffect(() => {
        getTextContent(id, tag).then(data =>
            setFullContent(data)
        );
    }, [])

    let text = "";

    if (Object.keys(fullContent).length === 0) {
        text = "loading..."
    } else if ("content" in fullContent) {
        text = fullContent.content;
        // text = id + " - " + tag + " - " + fullContent.toString()
    } else {
        text = "???"
    }
    return(
        <Text>{text}</Text>
    )
}


TextContent.propTypes = {
    id: PropTypes.number,
    tag: PropTypes.string
}